import styled from "styled-components";
import AiVector from "../../assets/images/ai-vectors.svg"

export const AiSectionContainer = styled.section`
    .mobile{
        display: none;
    }

    .ai-container{
        position: relative;
        background-image: url(${AiVector});
        height: 720px;
        background-position: center; 
        background-repeat: no-repeat; 
        background-size: contain;
        display: flex; 
        align-items: center;
        justify-content: center;
        overflow: hidden;
    }

    .ai-text{
        display: flex;
        flex-direction: column;
        align-items: center;
        justify-content: center;
        max-width: 756px;
    }

    .ai-title{
        text-align: center;
        text-shadow: 0px 3.484px 3.484px rgba(78, 110, 192, 0.30);
        font-family: Cairo;
        font-size: 2.5rem;
        font-style: normal;
        font-weight: 700;
        background: var(--s, linear-gradient(122deg, #FFF4D7 -22.23%, rgba(60, 102, 130, 0.96) 49.03%, rgba(62, 165, 222, 0.97) 91.79%));
        background-clip: text;
        -webkit-background-clip: text;
        -webkit-text-fill-color: transparent;
        span{
            font-size: 2rem;
        }
    }

    .ai-description{
        color:  #A2C138;
        margin: 2rem 11.25rem 0 11.25rem;
        text-align: center;
        font-family: Cairo;
        font-size: 1.1rem;
        font-style: normal;
        font-weight: 500;
    }

    .text-container{
        display: flex;
        gap: 1rem;
        width: 250px;
        height: 40px;
        padding: 8px var(--fontSize-16, 16px) 8px 8px;
        align-items: center;
        border-radius: 400px;
        background: linear-gradient(90deg, rgba(255, 255, 255, 0.12) 0.15%, rgba(112, 194, 23, 0.20) 100.01%);
        backdrop-filter: blur(64.23429870605469px);
    }

    .text-container:dir(ltr){
        background: linear-gradient(90deg, rgba(112, 194, 23, 0.20) 0.15%, rgba(255, 255, 255, 0.12) 100.01%);
    }

    .logistic-card{
        position: absolute;
        top: 8rem;
        right: 9rem;
        display: flex;
        align-items: center;
        gap: 1rem;
    }

    .delivery-card{
        bottom: 8rem;
        right: 9rem;
        position: absolute;
        display: flex;
        align-items: center;
        gap: 1rem;
    }

    .analysis-card{
        top: 8rem;
        left: 9rem;
        position: absolute;
        display: flex;
        align-items: center;
        gap: 1rem;
    }

    .delivery-time-card{
        bottom: 8rem;
        left: 9rem;
        position: absolute;
        display: flex;
        align-items: center;
        gap: 1rem;
    }


    .icon-container{
        display: flex;
        width: 25px;
        height: 25px;
        padding: 9.08px 9.145px 8.727px 8.662px;
        justify-content: center;
        align-items: center;
        flex-shrink: 0;
        border-radius: 348.389px;
        background: var(--background, #F0EEEF);
    }


    .title{
        color: var(--csk-0-f-3-d-57500, #0F3D57);
        font-family: Cairo;
        font-size: 16px;
        font-style: normal;
        font-weight: 700;
        line-height: 120%; /* 19.2px */
    }

    @media (max-width: 1024px){
        .ai-container{
            height: 650px;
            overflow: hidden;
        }

        .ai-text{
            max-width: 700px;
        }

        .logistic-card{
            top: 6rem;
            right: -3rem;
        }

        .delivery-card{
            bottom: 6rem;
            right: -3rem;
        }

        .analysis-card{
            top: 6rem;
            left: -3rem;
        }

        .delivery-time-card{
            bottom: 6rem;
            left: -3rem;
        }
    }

    @media (max-width: 768px){
        .mobile{
            display: none;
        }

        .ai-container{
            height: 500px;
        }

        .ai-text{
            max-width: 480px;
        }

        .ai-title{
            font-size: 1.8rem;
            span{
                font-size: 1.2rem;
            }
        }

        .ai-description{
            margin: .5rem 2rem 0 2rem;
            font-size: 1rem;
        }

        .text-container{
            width: 190px;
            height: 40px;
            gap: .8rem;
        }

        .logistic-card{
            top: 4rem;
            right: -3rem;
        }

        .delivery-card{
            bottom: 4rem;
            right: -3rem;
        }

        .analysis-card{
            top: 4rem;
            left: -3rem;
        }

        .delivery-time-card{
            bottom: 4rem;
            left: -3rem;
        }

        .icon-container{
            width: 20px;
            height: 20px;
            padding: 7px;
        }

        .title{
            font-size: .8rem;
        }

        .dot-icon{
            width: 1rem;
            height: 1rem;
        }
    }

    @media (max-width: 480px){
        .desktop{
            display: none;
        }

        .mobile{
            display: block;
        }

        .ai-container{
            height: 400px;
        }

        .ai-text{
            max-width: 400px;
        }

        .ai-title{
            font-size: 1.4rem;
            span{
                font-size: 1rem;
            }
        }

        .ai-description{
            margin: .5rem 2rem 0 2rem;
            font-size: .9rem;
        }

        .text-container{
            width: 140px;
            height: 30px;
            gap: .8rem;
        }

        .logistic-card{
            top: 3rem;
            right: -3rem;
        }

        .delivery-card{
            bottom: 3rem;
            right: -3rem;
        }

        .analysis-card{
            top: 3rem;
            left: -3rem;
        }

        .delivery-time-card{
            bottom: 3rem;
            left: -3rem;
        }

        .icon-container{
            width: 15px;
            height: 15px;
        }

        .icon{
            width: 20px;
            height: 20px;
        }

        .title{
            font-size: .5rem;
        }

        .dot-icon{
            display: none;
        }

    }
`