import { CardContainer } from './style'

interface Props {
    title: string;
    icon: JSX.Element;
    description: string
}

const Card = ({ title, icon, description }: Props) => {

    return (
        <>
            <CardContainer>
                <div className="card-container">
                    <div className='first-card'>
                        <div className='card-content'>
                            <div className='card-text'>
                                <div className='card-icon'>{icon}</div>
                                <div className='card-title'>{title}</div>
                            </div>
                            <p>{description}</p>
                        </div>
                    </div>
                    <div className='second-card'></div>
                    <div className='third-card'></div>
                </div>
            </CardContainer>
        </>
    )
}

export default Card