import styled from "styled-components";

export const PartnerContainer = styled.section`
    margin-bottom: 80px;
    position: relative;

    .partner-text{
        position: absolute;
        text-align: center;
        background-color: #f0eeef;//#f0eeef
        border-radius: 50%;
        top: -4.8rem;
        left: 0;
        z-index: 10;
        width: 100%;
        padding: 0 0 2.5rem 0;
    }

    .partner-title{
        color: #0F3D57;
        font-family: Cairo;
        font-size: 48px;
        font-style: normal;
        font-weight: 700;
        line-height: 66.5px; /* 138.542% */
        padding-top: 19px ;
    }

    .card{
        width: 200px;
        height: 400px;
        display: flex;
        align-items: center;
        justify-content: center;
    }

    .yellow-card{
        background-color: yellow;
    }

    .white-card{
        background-color: #FFFFFF;
    }

    .my-swiper{
        height: 100%;
        margin-top: 8.5rem;
    }

    .bottom-div{
        position: absolute;
        text-align: center;
        background-color: #f0eeef;//#f0eeef
        border-radius: 50%;
        bottom: -4.8rem;
        left: 0;
        z-index: 10;
        width: 100%;
        padding: 5rem 0;
    }

    .card-icon{
        width: 8rem;
        height: 8rem;
    }

    .card-small-icon{
        width: 12rem;
        height: 12rem;
    }

    @media (max-width: 1024px){}

    @media (max-width: 768px){
        .card{
            width: 200px;
            height: 300px;
        }

        .card-icon{
            width: 6rem;
            height: 6rem;
        }

        .card-small-icon{
            width: 10rem;
            height: 10rem;
        }
    }

    @media (max-width: 460px){
        .card{
            height: 200px;
        }

        .card-icon{
            width: 4rem;
            height: 4rem;
        }

        .chefz-icon{
            width: 2rem;
            height: 2rem;
        }

        .card-small-icon{
            width: 8rem;
            height: 8rem;
        }

        .bottom-div{
            bottom: -6rem;
            left: 0;
        }

        .partner-text{
            top: -6rem;
        }
    }

`