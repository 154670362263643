import styled from "styled-components";

export const CommonQuestionContainer = styled.section`
    display: flex;
    justify-content: center;
    align-items: center;
    flex-direction: column;

    .mobile{
        display: none;
    }

    .common-question-text{
        text-align: center;
    }

    .common-question-title:dir(ltr){
        font-size: 40px;
    }

    .common-question-title {
        color: #0F3D57;
        text-align: center;
        font-size: 2.2rem;
        font-style: normal;
        font-weight: 700;
        line-height: 66.5px; /* 138.542% */
    }

    .common-question-card{
        position: relative;
        margin-bottom: 16.5rem;
        width: auto;
    }

    .question-marks{
        width: 250px;
        position: absolute;
        top: -12.3rem;
        right: 0;
        z-index: 11;
    }

    @media (max-width: 1024px){
    }

    @media (max-width: 760px){
        .common-question-title{
            font-size: 2rem;
        }

        .common-question-title:dir(ltr){
            font-size: 2rem;
        }

        .question-marks{
            width: 200px;
            top: -9.5rem;
            right: 0;
        }
    }

    @media (max-width: 460px){
        .common-question-title{
            font-size: 1.5rem;
        }

        .common-question-title:dir(ltr){
            font-size: 1.5rem;
        }

        .question-marks{
            width: 130px;
            top: -5.6rem;
            right: 0;
        }

        .common-question-card{
            margin-bottom: 8rem;
        }
    }

`