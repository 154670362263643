import React, { useState } from 'react'
import { QuestionCardContainer } from './style'
import { ReactComponent as DownArrow } from "../../../assets/icons/down-arrow.svg"
import { ReactComponent as UpArrow } from "../../../assets/icons/up-arrow.svg"
import { useTranslation } from 'react-i18next'
import { QuestionsType } from '../../../types/questions.interface'

const QuestionCard = ({ title, description }: QuestionsType) => {
    const [openCard, setOpenCard] = useState<boolean>(false)

    const { t } = useTranslation()

    return (
        <QuestionCardContainer>
            <div className={`${openCard && 'add-shadow'} cards-container`}>
                <div className={`question-text ${openCard ? 'opened-card' : 'closed-card'}`}>
                    <div className='question'>{t(title)}</div>
                    {
                        openCard ?
                            <div className='card-icon' onClick={() => setOpenCard(false)}>
                                <UpArrow />
                            </div>
                            :
                            <div className='card-icon' onClick={() => setOpenCard(true)}>
                                <DownArrow />
                            </div>
                    }

                </div>
                {openCard && <div className='question-desc-container'>
                    <div className='question-description'>
                        {t(description)}
                    </div>
                </div>}
            </div>
        </QuestionCardContainer>
    )
}

export default QuestionCard