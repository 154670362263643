import { BrowserRouter } from 'react-router-dom';
import Home from './Pages/Home';
import { useTranslation } from 'react-i18next';
import { useEffect } from 'react';

function App() {
  const { i18n } = useTranslation();

  useEffect(() => {
    // Change the direction of the page based on the language
    const direction = i18n.language === 'ar' ? 'rtl' : 'ltr';
    document.documentElement.dir = direction;
  }, [i18n.language]);

  return (
    <BrowserRouter>
      <Home />
    </BrowserRouter>

  );
}

export default App;
