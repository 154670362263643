import styled from "styled-components";

export const AboutContainer = styled.section`
    margin-top: 60px;
    background-color: #0F3D57;
    
    .about-top{
        display: flex;
        justify-content: space-between;
        align-items: center;
    }

    .circle-img{
        max-width: 100%;
    }
    
    .about-imgs {
        position: relative; 
    }

    .about-text{
        color: white;
        padding: 50px;
        margin: auto;
        z-index: 0;
    }

    .about-title{
        font-size: 5rem;
        font-weight: 700;
        line-height: 10rem;
    }

    .about-description{
        font-size: 1.2rem;
        font-weight: 400;
        max-width: 700px;
        text-align: justify;
    }

    .circle-img:dir(ltr){
        transform: rotateY(180deg);
    }

    .car-img:dir(rtl){
        position: absolute;
        right: -30rem;
        bottom: 40%;
    }

    .car-container:dir(ltr){
        position: absolute;
        left: -30rem;
        bottom: 40%;
        transform: rotateY(180deg);
    }

    

    .green-bag:dir(rtl){
        max-width: 100%;
        position: absolute;
        right: 0;
        bottom: 16%;
    }

    .green-bag:dir(ltr){
        max-width: 100%;
        position: absolute;
        left: 0;
        bottom: 17%;
        transform: rotateY(180deg);
    }

    .gray-bag:dir(rtl){
        max-width: 100%;
        position: absolute;
        right: 100px;
        bottom: 15%;
    }

    .gray-bag:dir(ltr){
        max-width: 100%;
        position: absolute;
        left: 100px;
        bottom: 15%;
        transform: rotateY(180deg);
    }

    .cards{
        display: flex;
        gap: 2.5rem;
        justify-content: center;
    }

    @media (max-width: 1024px){
        .circle-img{
            height: 700px;
            object-fit: cover;
        }

        .car-img{
            height: 220px;
        }

        .car-img:dir(rtl){
            position: absolute;
            right: -15.5rem;
            bottom: 40%;
        }

        .car-container:dir(ltr){
            position: absolute;
            left: -15.5rem;
            bottom: 40%;
        }


        .about-description{
            font-size: 1rem;
            font-weight: 400;
            max-width: 500px;
        }

        .about-title{
            font-size: 2.9rem;
            line-height: 5rem;
        }

        .green-bag:dir(rtl){
            height: 160px;
            bottom: 7.5rem;
        }
        
        .gray-bag:dir(rtl){
            right: 80px;
            bottom: 7rem;
            height: 160px;
        }

        .green-bag:dir(ltr){
            height: 160px;
            bottom: 7.5rem;
        }
        
        .gray-bag:dir(ltr){
            left: 80px;
            bottom: 7rem;
            height: 160px;
        }

        .cards{
            gap: 5rem;
        }

        .service-icon{
            height: 2.5rem;
            width: 2.5rem;
        }
    }

    @media (max-width: 768px){
        .circle-img{
            height: 550px;
            object-fit: cover;
        }

        .car-img{
            height: 200px;
        }

        .car-img:dir(rtl){
            position: absolute;
            right: -16.7rem;
            bottom: 40%;
        }

        .car-container:dir(ltr){
            position: absolute;
            left: -16.7rem;
            bottom: 40%;
        }


        .about-description{
            font-size: .9rem;
            font-weight: 400;
            max-width: 350px;
        }

        .about-title{
            font-size: 2.5rem;
            line-height: 5rem;
        }

        .green-bag:dir(rtl){
            height: 130px;
            bottom: 4rem;
        }
        
        .gray-bag:dir(rtl){
            right: 40px;
            bottom: 4rem;
            height: 130px;
        }

        .green-bag:dir(ltr){
            height: 80px;
            bottom: 4rem;
        }
        
        .gray-bag:dir(ltr){
            left: 40px;
            bottom: 3.7rem;
            height: 80px;
        }

        .cards{
            display: flex;
            flex-direction: column;
            justify-content: space-between;
            gap: 2rem;
            margin-top: 2rem;
        }

        .service-icon{
            height: 2.5rem;
            width: 2.5rem;
        }
    }

    @media (max-width: 480px){
        .circle-img{
            height: 350px;
            object-fit: cover;
        }

        .car-img{
            height: 130px;
        }

        .car-img:dir(rtl){
            position: absolute;
            right: -12.5rem;
            bottom: 40%;
        }

        .car-container:dir(ltr){
            position: absolute;
            left: -12.5rem;
            bottom: 40%;
        }


        .about-description{
            display: none;
        }

        .about-title{
            font-size: 2rem;
            line-height: 5rem;
        }

        .green-bag:dir(rtl){
            height: 80px;
            bottom: 4rem;
        }
        
        .gray-bag:dir(rtl){
            right: 40px;
            bottom: 4rem;
            height: 80px;
        }

        .green-bag:dir(ltr){
            height: 80px;
            bottom: 4rem;
        }
        
        .gray-bag:dir(ltr){
            left: 40px;
            bottom: 3.7rem;
            height: 80px;
        }

        .cards{
            display: flex;
            flex-direction: column;
            justify-content: space-between;
            gap: 2rem;
            margin-top: 2rem;
        }

        .service-icon{
            height: 2.5rem;
            width: 2.5rem;
        }
    }
    
    /* margin-top: 60px;
    padding-bottom: 60px;
    background-color: #0F3D57;

    .car-img:dir(rtl){
        position: absolute;
        right: -30rem;
        bottom: 40%;
    }

    .car-container:dir(ltr){
        position: absolute;
        left: -30rem;
        bottom: 40%;
        transform: rotateY(180deg);
    }

    .green-bag:dir(rtl){
        position: absolute;
        right: 0;
        bottom: 16%;
    }

    .green-bag:dir(ltr){
        position: absolute;
        left: 0;
        bottom: 17%;
        transform: rotateY(180deg);
    }

    .gray-bag:dir(rtl){
        position: absolute;
        right: 100px;
        bottom: 15%;
    }

    .gray-bag:dir(ltr){
        position: absolute;
        left: 100px;
        bottom: 15%;
        transform: rotateY(180deg);
    }

    .about-text{
        color: white;
        max-width: 700px;
        padding: 50px;
        margin: auto;
    }

    .about-title{
        font-size: 5rem;
        font-weight: 700;
        line-height: 10rem;
    }

    .about-description{
        font-size: 1.2rem;
        font-weight: 400;
    }

    .cards{
        display: flex;
        padding: 0 5rem;
        justify-content: space-between;
    } */

`