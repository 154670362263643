import styled from "styled-components";

export const QuestionCardContainer = styled.section`
    width: 100%;          
    max-width: 900px;     
    margin: 24px auto;          

    .add-shadow{
        box-shadow: 
            0 4px 8px rgba(15, 61, 87, 0.4),    /* Custom blue shadow */
            0 8px 16px rgba(123, 147, 43, 0.4);
        border-radius: 16px; /* Set your border radius */
        overflow: hidden;
    }


    .question-text{
        display: flex;
        padding: 18px;
        justify-content: space-between;
        gap: 3rem;
        align-items: center;
        align-self: stretch;
        background: #FFF;
        border-radius: 16px 16px 0 0;
    }

    .closed-card{
        border-radius: var(--fontSize-16, 16px);
    }

    .question{
        color: #0F3D57;
        font-size: 1.3rem;
        font-style: normal;
        font-weight: 700;
    }

    .question-desc-container{
        background-color: #FFFFFF;
        width: 100%;          
        max-width: 800px;
        border-radius: 0 0 16px 16px;
    }

    .question-description{
        display: flex;
        max-width: 647px;
        height: 53px;
        flex-direction: column;
        justify-content: center;
        flex-shrink: 0;
        color: #7B932B;
        font-family: Cairo;
        font-size: 1rem;
        font-style: normal;
        font-weight: 500;
        padding: 0 24px 24px 24px;     
    }

    .card-icon{
        cursor: pointer;
    }

    @media (max-width: 1024px){}

    @media (max-width: 760px){
        .question{
            font-size: 1.1rem
        }

        .cards-container{
            margin: 0 1rem;
        }
    }

    @media (max-width: 460px){
        .question-text{
            padding: 16px;
        }

        .question{
            font-size: .9rem;
        }

        .question-description{
            font-size: .8rem;    
        }
    }

`