import { QuateRequestContainer } from './style'
import QuateImg from "../../assets/images/quote-img.png"
import Backages from "../../assets/images/backages.png"
import { useForm, SubmitHandler } from "react-hook-form";
import { useTranslation } from 'react-i18next';
import { HashLink as Link } from "react-router-hash-link"

type Inputs = {
    name: string,
    email: string,
    phone: string,
    serviceType: string,
    requestDescription: string,
};

const QuoteRequest = () => {
    const { t } = useTranslation();

    const { register, handleSubmit } = useForm<Inputs>();
    const onSubmit: SubmitHandler<Inputs> = data => console.log(data);

    return (
        <QuateRequestContainer id="quote-request">
            <div className='request-container'>
                <div className='quote-container'>
                    <div className="quote">
                        <form onSubmit={handleSubmit(onSubmit)} className='quote-form'>
                            <div className='quote-title'>{t("quoteRequest")}</div>
                            <div className='quote-description'>{t("fillquoteRequestForm")}</div>
                            <div className="form-row">
                                <input placeholder={t("name")} {...register("name")} className='form-field' />
                                <input placeholder={t("email")} {...register("email")} className='form-field' />
                            </div>
                            <div className="form-row">
                                <input placeholder={t("phone")} {...register("phone")} className='form-field' />
                                <div className='form-field'>
                                    <select {...register("serviceType")} className='select'>
                                        <option value="servic type">{t("serviceType")}</option>
                                        <option value="servic type">{t("serviceType")}</option>
                                        <option value="servic type">{t("serviceType")}</option>
                                    </select>
                                </div>
                            </div>
                            <div className="form-row">
                                <textarea rows={4} placeholder={t("requestDescription")} {...register("requestDescription")} className='form-field full-width-field' />
                            </div>
                            <Link to="#contact" className="contact-btn link">{t("contact")}</Link>
                        </form>
                        <div className='img-container'>
                            <img src={QuateImg} alt='quote' className='quote-img' />
                            <div className='backages-img-container'>
                                <img src={Backages} alt='backages' className='backages-img' />
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </QuateRequestContainer>
    )
}

export default QuoteRequest