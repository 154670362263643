import backgroundVideo from "../../assets/videos/hero-video.mp4";
import Header from "../Header/Header";
import { ReactComponent as GreenVector } from '../../assets/icons/green-vector.svg';
import { ReactComponent as BlueVector } from '../../assets/icons/blue-vector.svg';
import Whatsapp from "../../assets/animation/whatsapp.json"
import { Container } from './Style'
import { useTranslation } from "react-i18next";
import { Dispatch, SetStateAction } from 'react';
import Lottie from "react-lottie";
import { HashLink as Link } from "react-router-hash-link"

interface Props {
    setOpenSidebar: Dispatch<SetStateAction<boolean>>;
}

const Hero = ({ setOpenSidebar }: Props) => {
    const { t } = useTranslation()

    return (
        <Container id="home">
            <div className="video-container">
                <Header setOpenSidebar={setOpenSidebar} />
                <video src={backgroundVideo} autoPlay loop muted />
                <div className="overlay"></div>
                <GreenVector className="green-vector" />
                <BlueVector className="blue-vector" />
                <div className="hero-text-container">
                    <div className="hero-text">
                        <div className="h1">{t("heroTitle")}</div>
                        <p>{t("heroText")}</p>
                        <div className="hero-description">
                            <div className="sub-title">{t("wePresentToYou")} :</div>
                            <ul>
                                <li>{t("fastDelivery")}</li>
                                <li>{t("advanceShippingSolution")}</li>
                            </ul>
                            <div>{t("bookYourDelivery")}</div>
                        </div>
                        <Link to="#contact" className="contact-btn link">{t("contact")}</Link>
                    </div>
                </div>
            </div>
            <div className="whatsapp-img">
                <a
                    href="https://api.whatsapp.com/send/?phone=+966532963072&text=Hello+OnPoints"
                    target="_blank"
                    rel="noreferrer"
                >
                    <Lottie
                        options={{
                            loop: true,
                            autoplay: true,
                            animationData: Whatsapp,
                            rendererSettings: {
                                preserveAspectRatio: "xMidYMid slice",
                            },
                        }}
                    />
                </a>
            </div>
        </Container>
    )
}

export default Hero