import React from 'react'
import { SidebarContainer } from './style'
import { useTranslation } from 'react-i18next'
import { HashLink as Link } from "react-router-hash-link"
import { ReactComponent as Cross } from '../../assets/icons/cross.svg';
import { Dispatch, SetStateAction } from 'react';
import { motion } from 'framer-motion';

interface Props {
  setOpenSidebar: Dispatch<SetStateAction<boolean>>;
}

const Sidebar = ({ setOpenSidebar }: Props) => {
  const { t } = useTranslation()

  return (

    <SidebarContainer>
      <motion.div
        initial={{ scaleY: 0, translateY: '-100%' }}
        animate={{
          scaleY: 1,
          translateY: '0%',
          transition: { duration: 0.7, ease: [0.12, 0, 0.39, 0] },
        }}
        exit={{
          scaleY: 0,
          translateY: '-100%',
          transition: { duration: 0.7, ease: [0.22, 1, 0.36, 1] },
        }}
        className="sidebar-container"
      >
        <Cross className="close-icon" onClick={() => setOpenSidebar(false)} />
        <div className="sidebar-container">
          <li>
            <Link to="#home" className="link" onClick={() => setOpenSidebar(false)}>
              {t("home")}
            </Link>
          </li>
          <li>
            <Link to="#about" className="link" onClick={() => setOpenSidebar(false)}>{t("about")}</Link>
          </li>
          <li>
            <Link to="#quote-request" className="link" onClick={() => setOpenSidebar(false)}>{t("offerPrice")}</Link>
          </li>
          <li>
            <Link to="#contact" className="link" onClick={() => setOpenSidebar(false)}>{t("contact")}</Link>
          </li>
        </div>
      </motion.div>
    </SidebarContainer>
  )
}

export default Sidebar