import { QuestionsType } from "../../types/questions.interface";


export const Questions: QuestionsType[] = [
    {
        title: "question1",
        description: "question1description",
    },
    {
        title: "question2",
        description: "question2description",
    },
    {
        title: "question3",
        description: "question3description",
    },
    {
        title: "question4",
        description: "question4description",
    },
    {
        title: "question5",
        description: "question5description",
    },
    {
        title: "question5",
        description: "question5description",
    },
    {
        title: "question6",
        description: "question6description",
    },
    {
        title: "question7",
        description: "question7description",
    },
    {
        title: "question8",
        description: "question8description",
    },
    {
        title: "question9",
        description: "question9description",
    },
    {
        title: "question10",
        description: "question10description",
    },

]