import Hero from "../Components/Hero/Hero";
import About from "../Components/About/About";
import QuoteRequest from "../Components/QuoteRequest/QuoteRequest";
import Services from "../Components/Services/Services";
import AiSection from "../Components/Ai/AiSection";
import Join from "../Components/Join/Join";
import Partners from "../Components/Partners/Partners";
import CommonQuestion from "../Components/CommonQuestion/CommonQuestion";
import Footer from "../Components/Footer/Footer";
import Sidebar from "../Components/Sidebar/Sidebar";
import { useState } from "react";
import { AnimatePresence } from "framer-motion";

const Home = () => {
  const [openSidebar, setOpenSidebar] = useState<boolean>(false)
  // Add your code here to render the home page layout.
  return (
    <div>
      <AnimatePresence>
        {openSidebar &&
          <Sidebar setOpenSidebar={setOpenSidebar} />
        }
      </AnimatePresence>
      <Hero setOpenSidebar={setOpenSidebar} />
      <About />
      <QuoteRequest />
      <Services />
      <AiSection />
      <Join />
      <Partners />
      <CommonQuestion />
      <Footer />
    </div>
  );
};

export default Home;
