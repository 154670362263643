import React from 'react'
import { FooterContainer } from './style'
import { useTranslation } from 'react-i18next'
import { useForm, SubmitHandler } from "react-hook-form";
import { HashLink as Link } from "react-router-hash-link"
import FooterTransparentLogo from "../../assets/images/logos/LOGO_1.png"
import AppStore from "../../assets/images/appstore.png"
import GooglePlay from "../../assets/images/googleplay.png"
import SaudiVision from "../../assets/images/logo-saudi-vision-2030.png"
import { ReactComponent as LogoSvg } from "../../assets/images/logos/Logo-svg.svg"
import { ReactComponent as LinkedIn } from "../../assets/icons/social-media/linkedin.svg"
import { ReactComponent as Instagram } from "../../assets/icons/social-media/instagram.svg"
import { ReactComponent as FaceBook } from "../../assets/icons/social-media/facebook.svg"
import { ReactComponent as X } from "../../assets/icons/social-media/x.svg"

type Inputs = {
    firstName: string,
    lastName: string,
    email: string,
    phone: string,
    message: string,
};

const Footer = () => {
    const { t } = useTranslation()

    const { register, handleSubmit } = useForm<Inputs>();
    const onSubmit: SubmitHandler<Inputs> = data => console.log(data);

    return (
        <FooterContainer id="contact">
            <div className="footer-top">
                <div className='footer-text'>
                    <div className='footer-title'>{t("getInTouch")}</div>
                    <div className='footer-description'>{t("footerDescription")}</div>
                </div>
                <div className='footer-form'>
                    <form onSubmit={handleSubmit(onSubmit)} className='quote-form'>
                        <div className="form-row">
                            <input placeholder={t("firstName")} {...register("firstName")} className='form-field' />
                            <input placeholder={t("lastName")} {...register("lastName")} className='form-field' />
                        </div>
                        <div className="form-row">
                            <input placeholder={t("phone")} {...register("phone")} className='form-field' />
                            <input placeholder={t("email")} {...register("email")} className='form-field' />
                        </div>
                        <div className="form-row">
                            <textarea rows={4} placeholder={t("message")} {...register("message")} className='form-field full-width-field' />
                        </div>
                        <button type='submit' className='contact-btn'>{t("sendMessage")}</button>
                    </form>
                </div>
            </div>
            <div className='footer-middle'>
                <img src={FooterTransparentLogo} alt="logo" className='logo' />
                <img src={FooterTransparentLogo} alt="logo" className='logo' />
                <img src={FooterTransparentLogo} alt="logo" className='logo' />
                <img src={FooterTransparentLogo} alt="logo" className='logo' />
                <img src={FooterTransparentLogo} alt="logo" className='logo' />
                <img src={FooterTransparentLogo} alt="logo" className='logo' />
            </div>
            <div className="footer-bottom">
                <div className='footer-img'>
                    <img src={SaudiVision} alt="saudi-vision" className='saudi-vision' />
                </div>
                <div className='footer-menu'>
                    <div className='footer-bottom-title'>{t("menu")}</div>
                    <ul>
                        <li><Link to="#home" className="link">{t("home")}</Link></li>
                        <li><Link to="#about" className="link">{t("about")}</Link></li>
                        <li><Link to="#" className="link">{t("store")}</Link></li>
                        <li><Link to="#" className="link">{t("pricing")}</Link></li>
                        <li><Link to="#" className="link">{t("projrctManagement")}</Link></li>
                    </ul>
                </div>
                <div className='footer-menu'>
                    <div className='footer-bottom-title'>{t("ourProductsAndCategories")}</div>
                    <ul>
                        <li>{t("plumbingTool")}</li>
                        <li>{t("doors")}</li>
                        <li>{t("cleaningSupplies")}</li>
                        <li>{t("buildingMaterials")}</li>
                    </ul>
                </div>
                <div className='footer-menu'>
                    <div className='footer-bottom-title'>{t("contact")}</div>
                    <ul>
                        <li>
                            <a
                                href="https://api.whatsapp.com/send/?phone=+966532963072&text=Hello+OnPoints"
                                target="_blank"
                                rel="noreferrer"
                                className='link'
                            >
                                966532963072
                            </a>
                        </li>
                        <li>
                            <a
                                href="/#"
                                target="_blank"
                                rel="noreferrer"
                                className='link'
                            >
                                info@onpoint.sa
                            </a>
                        </li>
                    </ul>
                </div>
                <div className='footer-menu'>
                    <div className='footer-bottom-title'>{t("downloadApp")}</div>
                    <ul>
                        <li>
                            <a
                                href="/#"
                                target="_blank"
                                rel="noreferrer"
                                className='link'
                            >
                                <img src={AppStore} alt="app-store" className='download-app-img' />
                            </a>
                        </li>
                        <li>
                            <a
                                href="/#"
                                target="_blank"
                                rel="noreferrer"
                                className='link'
                            >
                                <img src={GooglePlay} alt="google-play" className='download-app-img' />
                            </a>
                        </li>
                    </ul>
                </div>
                <div className='footer-social-media-menu'>
                    <div className='footer-bottom-title'>{t("socialMedia")}</div>
                    <ul>
                        <li>
                            <LogoSvg />
                        </li>
                        <li className='social-media-icon'>
                            <a
                                href="/#"
                                target="_blank"
                                rel="noreferrer"
                                className='link'
                            >
                                <LinkedIn />
                            </a>
                            <a
                                href="/#"
                                target="_blank"
                                rel="noreferrer"
                                className='link'
                            >
                                <Instagram />
                            </a>
                            <a
                                href="/#"
                                target="_blank"
                                rel="noreferrer"
                                className='link'
                            >
                                <FaceBook />
                            </a>
                            <a
                                href="/#"
                                target="_blank"
                                rel="noreferrer"
                                className='link'
                            >
                                <X />
                            </a>
                        </li>
                    </ul>
                </div>
                <div className='mobile-social-media'>
                    <div className='mobile-logos'>
                        <LogoSvg />
                        <img src={SaudiVision} alt="saudi-vision" className='saudi-vision' />
                    </div>
                </div>
                <div className='mobile-social-media-icon'>
                    <a
                        href="/#"
                        target="_blank"
                        rel="noreferrer"
                        className='link'
                    >
                        <LinkedIn />
                    </a>
                    <a
                        href="/#"
                        target="_blank"
                        rel="noreferrer"
                        className='link'
                    >
                        <Instagram />
                    </a>
                    <a
                        href="/#"
                        target="_blank"
                        rel="noreferrer"
                        className='link'
                    >
                        <FaceBook />
                    </a>
                    <a
                        href="/#"
                        target="_blank"
                        rel="noreferrer"
                        className='link'
                    >
                        <X />
                    </a>
                </div>
            </div>
            <div className='copyright'>
                <span>{t("developedBy")}</span> <a href='https://zane-co.com/' target="_blank" rel="noreferrer" className='link'>Zane Technology</a> &copy; 2024
            </div>
        </FooterContainer>
    )
}

export default Footer