import React from 'react'
import { JoinContainer } from './style'
import { useTranslation } from 'react-i18next'
import { ReactComponent as Line } from "../../assets/images/line.svg"
import { ReactComponent as AddUserIcon } from "../../assets/icons/add-user-icon.svg"
import { ReactComponent as CustomerServiceIcon } from "../../assets/icons/customer-service-icon.svg"
import { ReactComponent as StorageSystemIcon } from "../../assets/icons/storage-system-icon.svg"
import { ReactComponent as GrowthPlansIcon } from "../../assets/icons/growth-plans-icon.svg"
import Rectangle from "../../assets/images/rectangle.svg"
import WhiteRectangle from "../../assets/images/white-rectangle.svg"
import MobileRectangle from "../../assets/images/rectangle-mobile.png"
import { HashLink as Link } from "react-router-hash-link"

const Join = () => {
    const { t } = useTranslation()
    return (
        <JoinContainer>
            <div className='join-text'>
                <div className='join-title'>{t("joinUs")}</div>
                <Line />
                <div className='join-sub-title'>{t("joinSubTitle")}</div>
                <div className='join-description'>{t("joinDescription")}</div>
                <Link to="#contact" className="contact-btn link"><AddUserIcon /> {t("joinNow")}</Link>
            </div>
            <div className='join-imgs desktop'>
                <div className='white-rectangle-container'>
                    <div className='rectangle-wrapper'>
                        <img src={WhiteRectangle} alt='white-rectangle' className='white-rectangle-img' />
                        <div className="centered-text">
                            <div className="text-title">{t("customerService")}</div>
                            <div className="text-description">{t("customerServiceDescription")}</div>
                        </div>
                        <div className='centered-icon'>
                            <CustomerServiceIcon className="icon" />
                        </div>
                    </div>
                    <div className='rectangle-wrapper'>
                        <img src={WhiteRectangle} alt='white-rectangle' className='white-rectangle-img middle-rectangle' />
                        <div className="centered-text">
                            <div className="text-title middle-title">{t("storageSystem")}</div>
                            <div className="text-description middle-description">{t("storageSystemDescription")}</div>
                        </div>
                        <div className='centered-icon'>
                            <div className='storage-icon-container'>
                                <StorageSystemIcon className="small-icon" />
                            </div>
                        </div>
                    </div>
                    <div className='rectangle-wrapper'>
                        <img src={WhiteRectangle} alt='white-rectangle' className='white-rectangle-img side-rectangle' />
                        <div className="centered-text">
                            <div className="text-title">{t("growthPlans")}</div>
                            <div className="text-description">{t("growthPlansDescription")}</div>
                        </div>
                        <div className='centered-icon'>
                            <div className='plans-icon-container'>
                                <GrowthPlansIcon className="small-icon" />
                            </div>
                        </div>
                    </div>
                </div>
                <img src={Rectangle} alt='rectangle' className='rectangle-img' />
            </div>
            <div className="mobile">
                <div className='mobile-rectangle-wrapper'>
                    <img src={MobileRectangle} alt='white-rectangle' className='mobile-rectangle' />
                    <div className="mobile-centered-text">
                        <div className="text-title">{t("customerService")}</div>
                        <div className="text-description">{t("customerServiceDescription")}</div>
                    </div>
                    <CustomerServiceIcon className="mobile-centered-icon" />
                </div>
                <div className='mobile-rectangle-wrapper'>
                    <img src={MobileRectangle} alt='white-rectangle' className='mobile-rectangle' />
                    <div className="mobile-centered-text">
                        <div className="text-title middle-title">{t("storageSystem")}</div>
                        <div className="text-description mobile-middle-description">{t("storageSystemDescription")}</div>
                    </div>
                    <div className='mobile-storage-icon-container'>
                        <StorageSystemIcon className="mobile-icon" />
                    </div>
                </div>
                <div className='mobile-rectangle-wrapper'>
                    <img src={MobileRectangle} alt='white-rectangle' className='mobile-rectangle' />
                    <div className="mobile-centered-text">
                        <div className="text-title">{t("growthPlans")}</div>
                        <div className="text-description">{t("growthPlansDescription")}</div>
                    </div>
                    <div className='mobile-storage-icon-container' style={{ backgroundColor: "#1E79AE" }}>
                        <GrowthPlansIcon className="mobile-icon" />
                    </div>
                </div>
            </div>
        </JoinContainer>
    )
}

export default Join