import styled from "styled-components";

export const HeaderContainer = styled.header`
    position: absolute;
    z-index: 3;
    width: 100%;
    top: 0;

    .header{
        display: flex;
        justify-content: space-between;
        align-items: center;
        padding: 1.5rem 6.2rem;
    }

    .mobile-header{
        display: none;
    }

    ul{
        display: flex;
        justify-content: center;
        align-items: center;
        gap: 2rem;
        list-style: none;
        text-align: center;
        font-size: 1.25rem;
        font-style: normal;
        font-weight: 700;
        line-height: 1.3rem; 
        .link{
            color: white;
        }
    }

    h3{
        color: #FFF;
        font-size: 1rem;
        font-style: normal;
        font-weight: 600;
        line-height: 1.3rem;
        display: flex;
        align-items: center;
        justify-content: center;
        gap: .5rem;
        cursor: pointer;
    }

    .earth-bg{
        background-color: #A2C138;
        width: 1.8rem;
        height: 1.8rem;
        border-radius: 50%;
        display: flex;
        align-items: center;
        justify-content: center;
        flex-shrink: 0;
    }

    .header-left-side{
        display: flex;
        align-items: center;
        justify-content: center;
        gap: .5rem;
    }


    @media (max-width: 1024px){
        .header{
            padding: .5rem 5rem;
        }

        .logo{
            height: 7rem;
            width: 11rem;
        }

        ul{
            font-size: 1.2rem;
        }

        h3{
            font-size: 1rem; 
        }

        .earth-icon{
            height: 1.5rem;
            width: 1.5rem;
        }

        .earth-bg{
            width: 1.9rem;
            height: 1.9rem;
        }
    }

    @media (max-width: 768px){
        .logo{
            height: 5rem;
            width: 9rem;
        }

        .header{
            padding: .5rem 3rem;
        }

        ul{
            font-size: 1rem;
        }

        h3{
            font-size: .9rem; 
        }

        .earth-icon{
            height: 1.5rem;
            width: 1.5rem;
        }

        .earth-bg{
            width: 2.2rem;
            height: 2.2rem;
        }
    }

    @media (max-width: 480px){
        .logo{
            height: 5rem;
            width: 7.5rem;
        } 

        .header{
            display: none;
        }

        .mobile-header{
            padding: 0 1rem;
            display: flex;
            justify-content: space-between;
            align-items: center;
        }

        h3 .text{
            display: none;
        }

        ul{
            display: none;
        }

        .earth-icon{
            height: 1.5rem;
            width: 1.5rem;
        }

        .earth-bg{
            width: 2.2rem;
            height: 2.2rem;
            box-shadow: 1px 1px rgba(0, 0, 0, 0.3);
        }
    }

`;