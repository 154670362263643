import styled from "styled-components";
import ClipPath from "../../assets/images/clip-path.svg"

export const FooterContainer = styled.section`
    .footer-top{
        background-image: url(${ClipPath});
        background-color: #0F3D57;
        padding: 40px 90px;
        background-repeat: no-repeat; 
        background-position: right;
        height: auto;
        display: flex;
        justify-content: space-between;
        align-items: center;
    }

    .footer-text{
        color: #ffffff;
    }

    .footer-title{
        font-family: Cairo;
        font-size: 2rem;
        font-style: normal;
        font-weight: 700;
        line-height: 72px;
        margin-bottom: 18px;
    }

    .footer-description{
        max-width: 27rem;
        font-family: Cairo;
        font-size: 1rem;
        font-style: normal;
        font-weight: 300;
        text-align: justify;
    }

    .footer-form{
        width: 50%;
    }

    .quote-form{
        width: 100%;
    }

    .quote-form button{
        margin: auto;
    }

    .form-row{
        display: flex; 
        justify-content: space-between;
        margin-bottom: 1rem;
        gap: 1.3rem;
    }

    .form-field{
        display: flex;
        width: calc(50% - 0.5rem);
        padding: 9px 9px;
        justify-content: flex-start;
        align-items: center;
        gap: .5rem;
        border-radius: .5rem;
        border: 1px solid  #F0EEEF;
        background: var(--gray-1, #F9FAFB);
        color: var(--text-sub, #374151);
        font-family: Cairo;
        font-size: 1rem;
        font-style: normal;
        font-weight: 400;
        line-height: normal;
        outline: none;
    }

    .full-width-field{
        width: 100%;
    }

    .footer-middle{
        background: var(--csk-0-f-3-d-57100, #D3EAF8);
        height: 55px;
        display: flex;
        align-items: center;
        justify-content: space-between;
        /* padding: 0 40px; */
        width: 100%;
        overflow: hidden;
    }

    .mobile-social-media{
        display: none;
    }
    
    .mobile-social-media-icon{
        display: none;
    }

    .footer-bottom{
        background-color: #0F3D57;
        padding: 40px 90px;
        display: flex;
        justify-content: space-between;
        align-items: start;
        height: auto;

        ul{
            list-style-type: none;
            padding: 0 0;
            color: #FFFFFF;
        }

        li{
            font-family: Cairo;
            font-size: 1rem;
            font-style: normal;
            font-weight: 500;
            line-height: 120%; /* 19.2px */
            padding: 7px;
        }
    }

    .footer-bottom .link{
        color: #FFF;
    }

    .footer-bottom-title{
        color: var(--Primary-Primary200, #B2DBF2);
        font-family: Cairo;
        font-size: 1.2rem;
        font-style: normal;
        font-weight: 300;
        line-height: 120%; /* 24px */
        letter-spacing: -0.6px;
    }



    .footer-img{
        position: relative;
        z-index: 9;
    }

    .circle-icon{
        position: absolute;
        top: 50%;
        left: 50%;
        transform: translate(-50%, -50%);
        z-index: 99;
    }

    .start-video{
        position: absolute;
        top: 50%;
        left: 50%;
        transform: translate(-50%, -50%);
        z-index: 99;
    }

    .download-app-img{
        width: 7rem;    
        height: 1.9rem;
    }

    .social-media-icon{
        display: flex;
        gap: 2rem;
        margin-top: 1rem;
    }

    .footer-social-media-menu{
        display: flex;
        flex-direction: column;
        align-items: center;
        gap: 1.5rem;
        justify-content: space-between;
    }

    .saudi-vision{
        width: 9rem;
        height: 7rem;
    }

    .footer-form .contact-btn{
        /* height: 3rem; */
        padding: 1.5rem 0;
    }

    .copyright{
       display: flex;
       align-items: center;
       justify-content: center;
       background-color: #0F3D57;
       color: #FFFFFF;
       padding: .8rem;
       font-size: 1rem;
    }

    .copyright .link{
        margin: auto .5rem;
        color: #a2c138;
        font-weight: 700;
    }

    @media (max-width: 1024px){
        .footer-top{
            padding: 2rem 2rem;
        }

        .footer-description{
            max-width: 25rem;
        }

        .footer-middle{
            padding: 0 0;
        }

        .footer-bottom{
            padding: 1rem 1rem;
        }

        .saudi-vision{
            width: 8rem;
            height: 6rem;
        }

        .footer-form .contact-btn{
            padding: 1.2rem 0;
        }

        .copyright{ 
            font-size: .9rem;
        }
    }

    @media (max-width: 760px){
        .footer-top{
            padding: 2rem 1rem;
            align-items: start;
            gap: 2rem;
        }

        .footer-title{
            font-size: 1.5rem;
            font-weight: 700;
            line-height: 72px;
            margin-bottom: 12px;
        }

        .footer-description{
            max-width: 26rem;
            font-size: .9rem;
            text-align: justify;
        }

        .form-row{
            display: flex; 
            flex-direction: column;
            justify-content: space-between;
        }

        .form-field{
            width: auto;
            max-width: 100%;
            padding: 4px 6px;
            font-size: .9rem;
        }

        .quote-form{
            width: 100%;
        }

        .quote-form .contact-btn{
            font-size: .9rem;
            padding: 1.2rem 0;
            margin: auto;
        }

        .footer-middle{
            height: 50px;
            padding: 0 0;
        }

        .logo{
            width: 70%;
            height: 2rem;
        }

        .footer-bottom{
            flex-direction: column;
            gap: 2rem;
            padding: 2rem 3rem;
        }

        .footer-social-media-menu{
            display: none;
        }

        .mobile-social-media{
            margin: auto;
            display: block;
        }
        
        .footer-img{
            display: none;
        }

        .mobile-social-media-icon{
            margin: auto;
            display: flex;
            gap: 2rem
        }

        .mobile-logos{
            display: flex;
            align-items: center;
            justify-content: center;
            gap: 2rem;
            margin-bottom: 1rem;
        }

        .copyright{ 
            font-size: .9rem;
        }
    }

    @media (max-width: 460px){
        .footer-description{
            display: none;
        }

        .footer-title{
            font-size: 1.2rem;
            margin-bottom: 0;
        }

        .footer-top{
            flex-direction: column;
            align-items: center;
        }

        .form-row{
            display: flex; 
            flex-direction: column;
            justify-content: space-between;
        }

        .form-field{
            padding: 4px 6px;
            font-size: .9rem;
        }

        .footer-form{
            width: 100%;
        }

        .quote-form{
            width: 100%;
        }

        .quote-form .contact-btn{
            width: 100%;
            font-size: .9rem;
            padding: 1.2rem 0;
        }

        .footer-bottom li{
            font-size: .9rem;
            font-weight: 300;
        }

        .footer-bottom {
            padding: 1rem 1rem;
        }

        .mobile-social-media{
            margin: auto;
        }

        .footer-img{
            display: none;
        }

        .mobile-social-media-icon{
            margin: auto;
            display: flex;
            gap: 2rem
        }

        .mobile-logos{
            display: flex;
            align-items: center;
            justify-content: center;
            gap: 2rem;
            margin-bottom: 1rem;
        }

        .copyright{ 
            font-size: .8rem;
        }
    }
`