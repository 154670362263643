import { ReactComponent as CardCircle } from "../../assets/icons/Card-circle.svg"
import { ReactComponent as LogisticIcon } from "../../assets/icons/logistic-icon.svg"
import { ReactComponent as DeliveryTimeIcon } from "../../assets/icons/delivery-time-icon.svg"
import { ReactComponent as AnalysisIcon } from "../../assets/icons/analysis-icon.svg"
import { ReactComponent as WayIcon } from "../../assets/icons/way-icon.svg"
import { AiSectionContainer } from './style'
import { useTranslation } from 'react-i18next'
import { motion } from 'framer-motion'
import { isMobile } from 'react-device-detect';

const AiSection = () => {
    const { t, i18n } = useTranslation()



    return (
        <AiSectionContainer>
            <div className="ai-container">
                <div className='ai-text'>
                    <div className='ai-title'>{t("aiSolve")}<br /><span>{t("powerOfAi")}</span></div>
                    <div className='ai-description'>{t("aiDescription")}</div>
                </div>
                <motion.div
                    className='logistic-card'
                    animate={{ opacity: 0 }}
                    whileInView={{ opacity: 1, x: i18n.language === "ar" ? (isMobile ? [65, -65] : [200, -200]) : (isMobile ? [65, -65] : [200, -200]) }}
                    transition={{ type: "tween", duration: .7 }}
                    drag="x"
                    dragConstraints={{ left: 200 }}
                >
                    <div className='logistic-text text-container'>
                        <div className='icon-container'>
                            <LogisticIcon className='icon' />
                        </div>
                        <div className='title'>{t("logisticTitle")}</div>
                    </div>
                    <CardCircle className="dot-icon" />
                </motion.div>
                <motion.div
                    className='delivery-card'
                    animate={{ opacity: 0 }}
                    whileInView={{ opacity: 1, x: i18n.language === "ar" ? (isMobile ? [65, -65] : [200, -200]) : (isMobile ? [65, -65] : [200, -200]) }}
                    transition={{ type: "tween", duration: .7 }}
                    drag="x"
                    dragConstraints={{ left: 200 }}
                >
                    <div className='delivery-text text-container'>
                        <div className='icon-container'>
                            <WayIcon className='icon' />
                        </div>
                        <div className='title'>{t("deliveryRouteTitle")}</div>
                    </div>
                    <CardCircle className="dot-icon" />
                </motion.div>
                <motion.div
                    className='analysis-card'
                    animate={{ opacity: 0 }}
                    whileInView={{ opacity: 1, x: i18n.language === "ar" ? (isMobile ? [-65, 65] : [-200, 200]) : (isMobile ? [-65, 65] : [-200, 200]) }}
                    transition={{ type: "tween", duration: .7 }}
                    drag="x"
                    dragConstraints={{ left: 200 }}
                >
                    <CardCircle className="dot-icon" />
                    <div className='analysis-text text-container'>
                        <div className='icon-container'>
                            <AnalysisIcon className='icon' />
                        </div>
                        <div className='title'>{t("analysisDataTitle")}</div>
                    </div>
                </motion.div>
                <motion.div
                    className='delivery-time-card'
                    animate={{ opacity: 0 }}
                    whileInView={{ opacity: 1, x: i18n.language === "ar" ? (isMobile ? [-65, 65] : [-200, 200]) : (isMobile ? [-65, 65] : [-200, 200]) }}
                    transition={{ type: "tween", duration: .7 }}
                    drag="x"
                    dragConstraints={{ left: 200 }}
                >
                    <CardCircle className='bottom-left-circle dot-icon' />
                    <div className='delivery-time-text text-container'>
                        <div className='icon-container'>
                            <DeliveryTimeIcon className='icon' />
                        </div>
                        <div className='title'>{t("deliveryInTimeTitle")}</div>
                    </div>
                </motion.div>
            </div>
        </AiSectionContainer>
    )
}

export default AiSection